// Desktop-cutoff
$desktop: 600px;

// Site Colors
$sand-light: #fed885;
$dark-orange: #ffa800;
$dark-blue: #172c40;
$olive: #714e0a;

//////////////////
/// Boxes Section
//////////////////
$height: 5rem;

// Top seperator styling
.website-divider-container-27295 {
    position: relative;
    padding-top: $height;
    overflow: hidden;
}

.divider-img-27295 {
    position: absolute;
    width: 200%;
    height: $height;

    transform: scale(1);
    bottom: 0px;
    left: 0px;
    fill: $sand-light;
}

.hflip {
    transform: scale(-1, 1.5);
}

.vflip {
    transform: scale(1, -1);
    top: 0px;
}

// Section styling
#square-bites {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 15vw 1fr;
    background-color: $sand-light;
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: $olive;

    @media (max-width: $desktop) {
        div {
            font-size: 1.2rem;
            line-height: auto;
        }
    }

    h1 {
        grid-column-start: 1;
        grid-column-end: 4;
        margin: auto;
        z-index: 1;
        font-size: clamp(1rem, 8vw, 3rem);
        font-style: italic;
    }

    #our_flagship {
        grid-row: 2;
        grid-column: 1;
        margin-top: auto;
        margin-bottom: auto;
    }

    #our_handcrafted {
        grid-row: 3;
        grid-column: 3;
        margin-top: auto;
        margin-bottom: auto;
    }

    #each_piece {
        grid-row: 4;
        grid-column: 1;
        margin-top: auto;
        margin-bottom: auto;
    }

    #hand_carfted_1 {
        grid-row: 2;
        grid-column-start: 2;
        grid-column-end: 4;
    }

    #hand_carfted_2 {
        grid-row: 3;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    #hand_carfted_3 {
        grid-row: 4;
        grid-column-start: 2;
        grid-column-end: 4;
    }


    @media (min-width: $desktop) {
        grid-template-rows: auto 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;

        #our_flagship {
            grid-row: 2;
            grid-column: 2;
        }

        #our_handcrafted {
            grid-row: 3;
            grid-column: 1;
        }

        #each_piece {
            grid-row: 3;
            grid-column: 3;
        }

        #hand_carfted_1 {
            grid-row: 2;
            grid-column: 1;
        }

        #hand_carfted_2 {
            grid-row: 3;
            grid-column: 2;
        }

        #hand_carfted_3 {
            grid-row: 2;
            grid-column: 3;
        }
    }
}