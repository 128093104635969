// Desktop-cutoff
$desktop: 600px;
$cutoff: 1200px;

// Site Colors
$sand-light: #ffd789;
$sand-dark: #e8c277;
$dark-orange: #ffa800;
$dark-blue: #172c40;
$olive: #714e0a;

.desktop_only {
    display: none;

    @media (min-width: $desktop) {
        display: initial;
    }
}

//////////////////
// LEGACY SECTION
//////////////////
#legacy {
    padding-top: 1rem;
    background-color: $dark-orange;
    color: $olive;
    display: grid;
    grid-template-columns: 30fr 70fr;
    grid-template-rows: auto auto auto;
    padding-left: 5%;
    padding-right: 5%;
    column-gap: 10%;

    #header {
        grid-column: 2;
        grid-row: 1;
        margin: 0;
        font-size: clamp(1rem, 8vw, 3rem);
    }

    #text {
        grid-column: 2;
        grid-row-start: 2;
        grid-row-end: 4;
        font-size: clamp(0.5rem, 3.5vw, 2rem);
        padding-top: 1rem;
    }

    #arch {
        grid-column: 1;
        grid-row-start: 1;
        grid-row-end: 3;

        img {
            width: 100%;
        }
    }

    #sprinkle {
        grid-column: 1;
        grid-row: 3;
        width: 75%;
        margin: auto;

        img {
            width: 100%;
        }
    }

    @media (min-width: $desktop) {
        grid-template-columns: 1fr 2rem 1.5fr 1fr;
        grid-template-rows: auto auto auto;
        row-gap: 0;
        column-gap: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 5rem;
        padding-bottom: 5rem;

        #header {
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row: 1;
            font-size: clamp(1rem, 8vw, 3rem);
            line-height: 3.2rem;
            font-style: italic;
            position: relative;
            margin: 0;
        }

        #text {
            grid-column: 3;
            grid-row: 2;
            margin-bottom: auto;
            font-size: inherit;
        }

        #arch {
            grid-column: 4;
            grid-row-start: 1;
            grid-row-end: 4;
            margin-top: auto;

            img {
                width: 100%;
            }
        }

        #food_top {
            grid-row: 1;
            grid-column: 1;

            img {
                width: 70%;
            }
        }

        #sprinkle {
            grid-column: 1;
            grid-row-start: 2;
            grid-row-end: 4;
            width: 100% !important;
            text-align: end;

            img {
                width: 75% !important;
            }
        }
    }
}