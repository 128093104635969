// Desktop-cutoff
$desktop: 600px;
$cutoff: 1366px;

// Site Colors
$sand-light: #ffd789;
$sand-dark: #e8c277;
$dark-orange: #ffa800;
$dark-blue: #172c40;

body {
  // Fonts taken from stylesheet at "https://use.typekit.net/bkj4toq.css"

  font-family: "lemonde-journal", serif;
  font-weight: 700;
  font-style: italic;

  font-family: "lemonde-journal", serif;
  font-weight: 400;
  font-style: normal;

  font-family: "azo-sans-web", sans-serif;
  font-weight: 400;
  font-style: normal;

  background-color: $dark-orange;

  div {
    font-family: "azo-sans-web", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  h1,
  h2 {
    font-family: "lemonde-journal", serif;
    font-weight: 700;
    font-style: italic;
    line-height: 2.5rem;
  }

  .desktop-only {
    display: none;

    @media (min-width: $desktop) {
      display: inherit;
    }
  }

  @media (min-width: $cutoff) {
    width: $cutoff;
    margin: auto;
  }
}

footer {
  background-color: black;
  color: $sand-light;

  padding: 2rem 2rem 1rem 2rem;

  font-size: 0.8rem;

  p {
    margin: 0;
  }

  @media (min-width: $desktop) {
    padding: 4rem 0 1rem 3rem;
  }
}