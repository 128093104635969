// Desktop-cutoff
$desktop: 600px;
$cutoff: 1200px;

// Site Colors
$sand: #ffd789;
$orange: #ffa800;
$olive: #714e0a;

/////////////////////
// BEHIND THE SCENES
/////////////////////

#behind_the_scenes {
    background-color: $sand;
    width: calc(100% - 6rem);
    padding: 3rem;

    h1 {
        color: $olive;
        font-size: clamp(1rem, 8vw, 3rem);
        margin: 0;
        font-family: "lemonde-journal", serif;
        font-weight: 700;
        font-style: italic;
        padding-bottom: 3rem;
    }

    #vid-gallery {
        display: flex;
        gap: 3rem;

    }

    #vidmodal {
        position: fixed;
        width: 90vw;
        left: 5vw;
        top: 20vh;
    }

    @media (min-width: $desktop) {
        width: calc(100% - 6rem);
        padding: 5rem 3rem 5rem 3rem;

        h1 {
            padding-bottom: 3rem;
        }

        #vid-gallery {
            gap: 10rem;
        }
    }
}