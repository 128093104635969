// Desktop-cutoff
$desktop: 600px;
$cutoff: 1920px;

// Site Colors
$sand-light: #ffd789;
$sand-dark: #e8c277;
$dark-orange: #ffa800;
$dark-blue: #172c40;
$olive: #714e0a;

///////////////////
/// Low Calorie section
///////////////////
#low-calorie {
    color: $olive;
    background-color: $sand-light;
    padding: 1rem;
    padding-top: 2rem;

    #plain-text {
        @media (max-width: $desktop) {
            font-size: 1.5rem;
        }
    }

    #low-calorie {
        font-size: 4rem;
        padding: 0;
        margin: 0;
        line-height: 4rem;
        font-weight: lighter;

    }

    #gluten-free {
        color: white;
        font-size: 4rem;
        padding: 0;
        padding-top: 15px;
        margin: 0;
        font-weight: lighter;
    }

    #video {
        margin-top: 3rem;
        width: 100%;
    }

    @media (min-width: $desktop) {
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 5rem;

        #plain-text {
            padding-bottom: 0.5rem;
        }

        #low-calorie,
        #gluten-free {
            font-style: italic;
            font-size: 4rem;
            font-weight: 400;
            line-height: 3rem;
        }

        #plain-text,
        #low-calorie,
        #gluten-free {
            //  padding-left: 15%;
            text-align: center;
        }

        #images {
            gap: 10%;
            padding-left: 7rem;
            padding-right: 7rem;
            padding-top: 5rem;
            width: calc(100% - 14rem);
        }
    }
}