// Desktop-cutoff
$desktop: 600px;
$cutoff: 1200px;

// Site Colors
$sand-light: #ffd789;
$sand-dark: #e8c277;
$dark-orange: #ffa800;
$dark-blue: #172c40;
$olive: #714e0a;

/////////////////////
// QUOTE SECTION
/////////////////////
#intro {
    background-color: $sand-dark;
    color: $dark-blue;


    .text {
        position: relative;
        z-index: 1;
        padding-left: 15%;
        padding-right: 5%;
        padding-bottom: 1rem;
        font-family: "lemonde-journal", serif;
        font-weight: 400;
        font-style: italic;
        font-size: 1.5rem;

        // M.H Fixes
        font-family: lemonde-journal, serif;
        font-size: 2rem;
        font-style: italic;
        font-weight: 400;
        padding-bottom: 1rem;
        padding-left: 7%;
        padding-right: 7%;
        position: relative;
        z-index: 1;
        padding-top: 7%;
        line-height: 2.5rem;
    }

    #images {
        display: flex;
        gap: 5rem;
        padding-top: 1rem;
        padding-left: 20%;
        padding-right: 20%;
        justify-content: space-between;

        // M.H Fixes
        display: flex;
        gap: 5rem;
        justify-content: space-between;
        padding-left: 7%;
        padding-right: 7%;
        padding-top: 1rem;
        padding-bottom: 4rem;

        img {
            width: 100%;
        }
    }

    @media (min-width: $desktop) {
        .text {
            padding: 5rem 9rem 5rem 9rem;
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
}