// Desktop-cutoff
$desktop: 600px;
$cutoff: 1200px;

// Site Colors
$sand-light: #ffd789;

/////////////////
/// Video Section
/////////////////
#videoSection {
    background-color: $sand-light;
    margin-top: 10px;
    margin-bottom: -5px; // weird fix 
}