// Desktop-cutoff
$desktop: 600px;
$cutoff: 1200px;

// Site Colors
$sand-light: #ffd789;
$sand-dark: #e8c277;
$dark-orange: #ffa800;
$dark-blue: #172c40;
$olive: #714e0a;

//


#contactForm {
    background-color: $dark-blue;
    color: white;
    padding: min(4rem, 12vw);

    .title {
        grid-row: 1;
        grid-column: 1;
        text-align: center;

        h2 {
            font-size: 3rem;
            margin: 0;
            color: $sand-light;
        }
    }

    form {
        grid-column: 1;
        grid-row: 2;

        input[type="text"],
        select,
        textarea {
            width: 100%;
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 4px;

            box-sizing: border-box;
            margin-top: 6px;
            margin-bottom: 16px;
            resize: none;
        }

        input[type="submit"] {
            background-color: $olive;
            color: white;
            padding: 12px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }

        input[type="submit"]:hover {
            background-color: $sand-dark;
        }

        textArea {
            height: 5rem;
        }
    }

    @media (min-width: $desktop) {
        padding-left: 10%;
        padding-right: 10%;
        display: grid;
        grid-template-columns: 7fr 3fr;
        grid-template-rows: auto auto;
        padding-top: 10rem;
        padding-bottom: 10rem;


        #title {
            grid-row: 1;
            grid-column: 2;

            h2 {
                font-size: clamp(1rem, 8vw, 3rem);
                font-family: "lemonde-journal", serif;
                font-weight: 700;
                font-style: italic;
            }
        }

        #team-at {
            display: none;
        }

        #form {
            grid-row: 2;
            grid-column: 1;
            padding-right: 5rem;
        }
    }
}